import { IDBPDatabase, openDB, StoreNames } from 'idb'
import { ActivityCategoryDependencyTree } from '../../../api/ActivityCategory'
import { ChangesInfo } from '../../../api/Application'
import { IdDisplayPair } from '../../../api/Common'
import { OrganicCertificationCategoryView } from '../../../api/OrganicCertificationCategories'
import { DetailedCommunityView } from '../../../api/Region'
import { SupportedLanguage, TenantId } from '../../../api/WellKnowIds'
import { AppTheme } from '../../../theme'

export type StoredSettings =
    {
        dataGridId: string
        columnsOrdered: React.Key[]
    }

interface MyDbSchema {
    'datagrid-settings': {
        key: string
        value: StoredSettings
    },
    'settings': {
        key: 'internationalization'
        value: {
            tenant?: TenantId
            language?: SupportedLanguage
        }
    },
    'activity-category-cache': {
        key: string
        value: {
            date: Date
            tree: ActivityCategoryDependencyTree
        }
    },
    'region-cache': {
        key: string
        value: {
            date: Date
            data: DetailedCommunityView[]
        }
    },
    'certification-category-cache': {
        key: string
        value: {
            date: Date
            categories: OrganicCertificationCategoryView[]
        }
    },
    'tag-cache': {
        key: string
        value: {
            date: Date
            tags: ChangesInfo[]
        }
    },
    'uom-cache': {
        key: string
        value: {
            date: Date
            uom: IdDisplayPair[]
        }
    },
    'theme': {
        key: 'current'
        value: AppTheme
    }
}

const createObjectStoreIffNotExists = (db: IDBPDatabase<MyDbSchema>, store: StoreNames<MyDbSchema>, optionalParameters?: IDBObjectStoreParameters) => {
    if (!db.objectStoreNames.contains(store))
        db.createObjectStore(store, optionalParameters)
}

const deleteObjectStoreIffExists = (db: IDBPDatabase<MyDbSchema>, store: StoreNames<MyDbSchema>) => {
    if (db.objectStoreNames.contains(store))
        db.deleteObjectStore(store)
}

export const openApplicationIdb = () => openDB<MyDbSchema>('bio-app', 12,
    {
        upgrade: (db, _oldVersion, _newVersion) => {
            createObjectStoreIffNotExists(db, 'datagrid-settings', { keyPath: 'dataGridId' })
            if (_newVersion && _newVersion >= 12)
                deleteObjectStoreIffExists(db, 'release-notes')
            createObjectStoreIffNotExists(db, 'settings')
            createObjectStoreIffNotExists(db, 'activity-category-cache')
            createObjectStoreIffNotExists(db, 'region-cache')
            createObjectStoreIffNotExists(db, 'certification-category-cache')
            createObjectStoreIffNotExists(db, 'tag-cache')
            createObjectStoreIffNotExists(db, 'theme')
            createObjectStoreIffNotExists(db, 'uom-cache')
        },
    })